import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { Layout } from "../components";
import { getContent } from "../helper";
import { HomeLayout } from "../layouts";

const Home = props => {
  const data = getContent(props?.data);

  return (
    <Layout title="Buy Imperial Shih Tzu Pups" {...props}>
      <Helmet>
        <meta
          name="description"
          content="Welcome to Shih Tzu Alley! We are AKC Shih Tzu breeders located in Lake Elsinore, CA, that specialize in the highest quality Teacup and Imperial Shih Tzu pups."
        />
        <meta
          name="keywords"
          content="Imperial Shih Tzu Puppies for Sale, shih tzu puppies for sale los angeles, shih tzu for sale san diego, Teacuo Shih Tzu Puppies For Sale, AKC Shih Tzu Puppies for sale, Shih Tzu Puppies for Sale, Tiny Shih Tzu Puppies for sale, Shih Tzu Puppies for sale in Fullerton, Shih Tzu Puppies for sale in Irvine, Shih Tzu Puppies for sale in Newport Beach, Shih Tzu Puppies for sale in Los Angeles, Shih Tzu Puppies for sale in Mission Viejo, Shih Tzu Puppies for sale in Lake Forest, Shih Tzu Puppies for sale in Brea, Shih Tzu Puppies For Sale in La Habra Heights, Shih Tzu Puppies for Sale in Costa Mesa, Shih Tzu Puppies for sale in Las Vegas, Shih Tzu Puppies for Sale in Anaheim Hills"
        />
      </Helmet>
      <HomeLayout {...data} />
    </Layout>
  );
};

export default Home;

export const query = graphql`
  query {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/home/" } }) {
      nodes {
        html
        frontmatter {
          title
          pictures {
            picture
          }
          contact
          content
        }
      }
    }
  }
`;
